import {
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from '@backstage/core-plugin-api';
import { SwEaseAPI } from './SwEaseAPI';

import {
  ADD_ORGANIZATION_ACTION,
  Answer,
  API,
  PARAM,
  UPDATE_ORGANIZATION_ACTION,
} from '@roche-ease/plugin-sw-ease-common';
import {
  AnswerCommentRequest,
  AnswerRequest,
  AnswerRequestParams,
  QuestionResponse,
  UserOrgProductResponseBody,
} from './response/Types';
import { SwEaseFetchAPI } from './SwEaseFetchAPI';

export const swEaseApiRef = createApiRef<SwEaseAPI>({
  id: 'plugin.sw-ease.api',
});

export class SwEaseClient implements SwEaseAPI {
  private readonly fetchApi: SwEaseFetchAPI;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi: FetchApi }) {
    this.fetchApi = new SwEaseFetchAPI(
      'sw-ease-backend',
      options.discoveryApi,
      options.fetchApi,
    );
  }

  saveOrganization(jsonData: any, action: string): Promise<void> {
    let api = API.SAVE_ORGANIZATION.replace(
      PARAM.ORG_ACTION,
      UPDATE_ORGANIZATION_ACTION,
    );
    if (action === ADD_ORGANIZATION_ACTION) {
      api = API.SAVE_ORGANIZATION.replace(
        PARAM.ORG_ACTION,
        ADD_ORGANIZATION_ACTION,
      );
    }

    return this.fetchApi.fetch(
      api,
      `Failed to save the organization.`,
      jsonData,
    );
  }

  getOrganizationData(key: string): Promise<any> {
    const api = API.GET_ORGANIZATION.replace(PARAM.ORG_ID, key);

    return this.fetchApi.fetch(
      api,
      `Failed to get organization with key: ${key}.`,
    );
  }

  getAllOrganization(): Promise<any> {
    return this.fetchApi.fetch(
      API.GET_ALL_ORGANIZATION,
      `Failed to get user organization .`,
    );
  }

  async getQuestion(yamlQuestionId: string): Promise<QuestionResponse> {
    const api = API.GET_QUESTION.replace(
      PARAM.YAML_QUESTION_ID,
      yamlQuestionId,
    );

    return this.fetchApi.fetch(
      api,
      `Failed to get question with yamlQuestionId: ${yamlQuestionId}.`,
    );
  }

  async saveAnswer(answer: AnswerRequest): Promise<Answer> {
    return this.fetchApi.fetch(
      API.SAVE_ANSWER,
      `Failed to save answer for question: ${answer.yaml_question_id}.`,
      answer,
    );
  }

  async updateAnswerComment(comment: AnswerCommentRequest): Promise<Answer> {
    const api = API.ANSWER_COMMENT_POST.replace(
      PARAM.ANSWER_ID,
      comment.answer_id,
    );
    return this.fetchApi.fetch(
      api,
      `Failed to update answer comment for question: ${comment.answer_id}.`,
      comment,
    );
  }

  async getUserOrgProduct(): Promise<UserOrgProductResponseBody[]> {
    return this.fetchApi.fetch(
      API.ORG_PRODUCT_GET,
      `Failed to get user org product.`,
    );
  }

  async getAnswersByGroup(question: AnswerRequestParams): Promise<Answer[]> {
    const api = API.GET_ANSWER.replace(
      PARAM.ORG_PRODUCT_ID,
      question.selectedOrgProduct,
    );
    return this.fetchApi.fetch(
      api,
      `Failed to get answer for question based on user session`,
      question,
    );
  }
}
