import { StatusCodes } from 'http-status-codes';

export const PARAM = {
  EMAIL: ':email',
  YAML_QUESTION_ID: ':yaml_question_id',
  USER_ID: ':user_id',
  ORG_PRODUCT_ID: ':org_product_id',
  ANSWER_ID: ':answer_id',
  ASSESSOR_ID: ':assessor_id',
  ORG_ID: ':org_id',
  ORG_ACTION: ':org_action',
};

export const API = {
  PREFIX: '/api',
  HEALTH_CHECK_LIVENESS: '/health/liveness',
  HEALTH_CHECK_READINESS: '/health/readiness',
  USER_POST: '/user',
  GET_USER: `/user/${PARAM.EMAIL}`,
  ORG_PRODUCT_GET: '/org_product',
  QUESTION_POST: '/question',
  GET_QUESTION: `/question/${PARAM.YAML_QUESTION_ID}`,
  SAVE_ANSWER: '/answer',
  GET_ANSWER: `/answer/all/${PARAM.ORG_PRODUCT_ID}`,
  ANSWER_COMMENT_POST: `/answer/${PARAM.ANSWER_ID}`,
  SAVE_ORGANIZATION: `/administration/${PARAM.ORG_ACTION}`,
  GET_ORGANIZATION: `/administration/org/${PARAM.ORG_ID}`,
  GET_ALL_ORGANIZATION: `/administration/all/org`,
};

export const HTTP_OK_CODE = 200;
export const OPERATION_SUCCESSFUL = {
  code: StatusCodes.OK,
  message: 'Operation successful',
};
export const BAD_REQUEST_CODE = 400;
export const UNAUTHORIZED_ERROR_CODE = 401;
export const UNAUTHORIZED_USER_ERROR_CODE = {
  code: StatusCodes.UNAUTHORIZED,
  message: 'Unauthorized user',
};
export const NOT_FOUND_ERROR_CODE = StatusCodes.NOT_FOUND;
export const UNEXPECTED_ERROR_CODE = {
  code: StatusCodes.INTERNAL_SERVER_ERROR,
  message: 'Internal server error, please try again',
};

export const ADD_ORGANIZATION_ACTION = 'addOrganization';
export const UPDATE_ORGANIZATION_ACTION = 'updateOrganization';
